import {
  FbaInboundDocumentListResults,
  MarketplacesService,
  User,
} from "../../api-client";
import {
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
} from "react-icons/md";
import { Filter } from "./Filter";
import { TrnTable } from "./TrnTable";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import ReactLoading from "react-loading";
import { useState, useEffect } from "react";
import { StatusType } from "../../type/stepsType";
import { handleError } from "../../utils/functions";

export const Homepage = ({ userInfo }: { userInfo: User | undefined }) => {
  const [marketplaceId, setMarketplaceId] = useState<string>();

  const [packingStatusSelected, setPackingStatusSelected] = useState<StatusType[]>([
    "PARTIALLY",
    "COMPLETE",
  ]);
  const [receiveStatusSelected, setReceiveStatusSelected] = useState<StatusType[]>([
    "PENDING",
    "PARTIALLY",
  ]);
  const [trnList, setTrnList] = useState<
    FbaInboundDocumentListResults | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    if (!userInfo) return;
    getTrn(marketplaceId, packingStatusSelected, receiveStatusSelected);
  }, [userInfo]);


  const getTrn = async (
    marketplaceIdParam: string | undefined = marketplaceId,
    packingStatusParam: StatusType[] = packingStatusSelected,
    receiveStatusParam: StatusType[] = receiveStatusSelected
  ) => {
    setLoading(true);
    try {
      const response =
        await MarketplacesService.getMarketplacesAmazonFbaInbound({
          query: {
            mpId: marketplaceIdParam ,
            packingStatus: packingStatusParam,
            receiveStatus: receiveStatusParam,
          },
        });
      if (response.error) {
        const { error, errors } = response.error;
        if (error || errors) {
          handleError(response.error);
          setLoading(false);
          setMarketplaceId(undefined);
          return;
        }
      }

      setTrnList(response.data);

      setLoading(false);
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    if (!marketplaceId) return;
    let offset = currentPage * 50 - 50;
  }, [currentPage]);

  const goToPage = (pageNum: number) => {
    setCurrentPage(Math.max(1, Math.min(trnList!.limit!, pageNum)));
  };

  return (
    <div className="p-5 px-6 bg-white min-h-[90vh]">
      <div>
        <div className="flex flex-col md:flex-row justify-between items-center my-4">
          <h1 className="text-3xl font-semibold">Shipments</h1>
          <Link
            className="p-3 bg-blue-600 hover:bg-blue-700 shadow-md text-white font-semibold rounded-lg transition duration-200 ease-in-out flex items-center gap-1.5"
            to={"/new-transfer"}
          >
            <FaPlus size={20} />
            Add New Transfer
          </Link>
        </div>
        <div className="my-5">
          {userInfo && (
            <Filter
              getTrn={getTrn}
              marketplaceId={marketplaceId}
              setMarketplaceId={setMarketplaceId}
              setPackingStatusSelected={setPackingStatusSelected}
              setReceiveStatusSelected={setReceiveStatusSelected}
              packingStatusSelected={packingStatusSelected}
              receiveStatusSelected={receiveStatusSelected}
            />
          )}
        </div>
        <div>
          {!loading ? (
            trnList ? (
              <>
                <div className="mt-8 border-gray-400 overflow-x-auto">
                  <div>
                    {trnList.results?.length && trnList.results?.length > 0 ? (
                      <div className="shadow">
                        <TrnTable data={trnList.results} userInfo={userInfo} />
                      </div>
                    ) : (
                      <p className="mt-2 text-[22px] text-gray-500">
                        There are no TRNs associated at the moment.
                      </p>
                    )}
                  </div>
                </div>
                {trnList.totalCount! > trnList.limit! && (
                  <div className="flex justify-center items-center gap-4 text-xl mt-2">
                    <button
                      onClick={() => goToPage(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="disabled:opacity-40"
                    >
                      <MdKeyboardDoubleArrowLeft size={30} />
                    </button>
                    <span>
                      Page {currentPage} of{" "}
                      {Math.ceil(trnList.totalCount! / trnList.limit!)}
                    </span>
                    <button
                      onClick={() => goToPage(currentPage + 1)}
                      disabled={
                        currentPage >=
                        Math.ceil(trnList.totalCount! / trnList.limit!)
                      }
                      className="disabled:opacity-40"
                    >
                      <MdKeyboardDoubleArrowRight size={30} />
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div></div>
            )
          ) : (
            <div className="w-full flex justify-center mt-10">
              <ReactLoading
                type="spinningBubbles"
                height="20%"
                width="20%"
                color="#03c160"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
