import Swal from "sweetalert2";
import Cookies from "js-cookie";
import axios from "axios";
import { client } from "@hey-api/client-fetch";
import { ErrorResponse } from "../api-client";

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return "";
  if (string === "NA") return "N/A";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const statusType = [
  { value: "PENDING", color: "bg-yellow-500", label: "Pending" },
  { value: "PARTIALLY", color: "bg-orange-500", label: "Partially" },
  { value: "COMPLETE", color: "bg-green-500", label: "Complete" },
  { value: "NA", color: "bg-gray-400", label: "N/A" },
];

export const handleError = (response: ErrorResponse) => {
  debugger
  const errorMessage = response?.error;
  const errorData = response?.errors;

  let errorMessageString = "";
  if (errorMessage) {
    errorMessageString = errorMessage;
  } else if (errorData) {
    errorMessageString = errorData.join("\n");
  }

 Swal.fire({
   title: "Something went wrong",
   text: errorMessageString,
   position: "top",
   confirmButtonText: "Close",
   ...swalStyleError,
 }).then((result) => {
   if (result.isConfirmed) {
     Swal.close();
   }
 });
};

export const handleCookie = async () => {
  const queryParameters = new URLSearchParams(window.location.search);
  let token = queryParameters.get("token");

  if (!token) {
    let cookieToken = Cookies.get("report-api-token");
    if (cookieToken) {
      token = cookieToken;
    }
  }

  if (token) {
    Cookies.set("report-api-token", token, { expires: 7, path: "/" });
  }

  const AuthorizationToken =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_TOKEN
      : token;

  axios.defaults.headers.common["Authorization"] = AuthorizationToken;

  client.interceptors.request.use((request, options) => {
    request.headers.set("Authorization", AuthorizationToken as string);
    return request;
  });
};

export const handleSuccess = (
  title: string,
  refresh: boolean = false
): void => {
  Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    customClass: {
      title: "!w-fit",
      popup: "!w-fit",
    },
  })
    .fire({
      icon: "success",
      title,
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (refresh) {
          window.location.reload();
        }
        Swal.close();
      }
    });
};

export const swalStyle = {
  allowOutsideClick: false,
  backdrop: false,
  customClass: {
    popup: "pb-2 w-fit",
    modal: "w-fit bg-red-500",
    actions: "!mt-0",
    confirmButton: " !bg-gray-500 border-none !shadow  focus:!shadow py-1",
    cancelButton: "!bg-red-500 border-none !shadow focus:!shadow py-1",
    title: "text-xl font-normal pt-2 px-4 ",
    htmlContainer: " !text-gray-500 !text-base !mt-1 mb-0",
  },
  showClass: {
    popup: "animate__animated animate__slideInDown animate__faster",
  },
  hideClass: {
    popup: "animate__animated animate__fadeOutUp animate__faster",
  },
};

export const swalStyleError = {
  allowOutsideClick: false,
  backdrop: false,
  customClass: {
    popup: "pb-2 w-fit bg-red-600 !text-white min-w-64",
    modal: "w-fit bg-red-500",
    actions: "!mt-2 mb-2 w-full flex justify-end px-1",
    confirmButton:
      "!bg-white !text-red-600 !shadow focus:!shadow py-1 px-1.5 !text-sm !mt-0.5 !mb-0",
    cancelButton: "!bg-red-500 border-none !shadow focus:!shadow py-1",
    title: "text-base pt-2 px-2 !max-w-[450px] !text-left mb-2",
    htmlContainer:
      "!text-base !text-red-100 !text-left !p-1 !px-2 max-w-[300px] mb-0 mt-1",
  },
  showClass: {
    popup: "animate__animated animate__slideInDown animate__faster",
  },
  hideClass: {
    popup: "animate__animated animate__fadeOutUp animate__faster",
  },
};
